import React, {useContext, useEffect} from 'react';
import {UserContext, track} from "@cg-squad/ui-components";
import consts from "../constants/consts";
import {STRINGS} from "../constants/strings";
import {navigate} from "gatsby";
import '../css/pages/callback.scss';
import mixpanel from "../constants/mixpanel";
import {initializeLock} from "@cg-squad/ui-components/dist/utils/auth0";
import {StorageService, api} from "@cg-squad/ui-components/dist/utils";

const Callback = (props) => {

    const userContext = useContext(UserContext);

    let lock;

    const initLock = () => {
        lock = initializeLock();
    }

    useEffect(() => {
        checkAuthenticationAndUser();
    }, []);

    const navigateToPage = () => {
        const url = new URL(window.location.href);
        const r = url.searchParams.get('r');
        navigate(r);
    }

    const removeUserSession = () => {
        StorageService.remove(consts.USER_STORAGE_KEY);
        userContext.updateState('user', null);
    }

    const checkAuthenticationAndUser = () => {
        if (!lock) {
            initLock();
        }
        lock.checkSession({}, function (error, authResult) {
            if (error || !authResult) {
                console.log(error);
                removeUserSession();
                navigateToPage();
            } else {
                track(mixpanel.MIXPANEL_LOGIN_FLOW.LOGIN_SUCCESS);
                debugger
                // user has an active session, so we can use the accessToken directly.
                lock.getUserInfo(authResult.accessToken, async (error, user) => {
                    if (error) {
                        console.log(error);
                        removeUserSession();
                        navigateToPage();
                        return;
                    }

                    let userData = StorageService.get(consts.USER_STORAGE_KEY);
                    if (!userData) {
                        userData = await api.post(`users`, user, {token: authResult.accessToken});
                    }
                    userContext.updateState('openAvatarDialog', false);
                    userContext.updateState('user', userData);
                    fetch(`https://api.agegroup.io/subscribe?name=${userData.name}&email=${userData.email}&website=${STRINGS.SUBSCRIPTION_TAG}`);
                        /*.then(resp => {
                            return resp.json()
                        })
                        .then(res => {
                            console.log(res);
                        });*/
                    navigateToPage();
                });
            }
        });
    }

    return (
        <div className={"w-screen h-screen flex"}>
            <div className={"w-[200px] m-auto"}>
                <img width={200} src={'/images/logos/logo.svg'} alt={'Age Times Logo'}/>
                <div className="loading-bar-container mt-2">
                    <div className="loading-bar"/>
                </div>
            </div>
        </div>
    )
}

export default Callback
